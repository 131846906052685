<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <router-link v-if="is_API_loaded" :to="{ name: 'new-customer' }">
        <vs-button size="large"><span class="font-medium">新增會員</span></vs-button>
      </router-link>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex px-4 mb-2 space-x-3">
        <vs-button @click="open_drawer" class="flex-shrink-0" border color="success">進階篩選</vs-button>
        <vs-input class="w-full" v-model="searchTitle" @blur="search" @keypress.enter="search" placeholder="以姓名、電話或電子郵件來搜尋" />
      </section>

      <!-- filter 區塊 -->
      <section class="filter_section">
        <div class="filter_box" v-for="(box, str) in filterBoxAry" :key="str" v-if="box.status !== ''">
          <p>{{ box.label }}{{ box.status }}</p>
          <button @click="filterBoxCancel(box, str)" class="mb-1">
            <svg-icon icon-class="cancel" className="gray-text-light xs"></svg-icon>
          </button>
        </div>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 位</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 位</span>
            <span v-else class="text-xs">共 {{ customers.length }} 位</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="delete_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title">會員名稱</span>
              <span class="w-36">會員等級</span>
              <span class="w-36">總消費金額</span>
              <span class="w-24">總消費筆數</span>
              <span style="width: 187px">註冊時間</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in customers" :key="item.smno">
            <router-link
              :to="{
                name: 'edit-customer',
                params: {
                  id: item.smno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.smno"></vs-checkbox>

              <!-- 信箱是否已認證 -->
              <div v-if="item.eactivate" @click.stop="" class="absolute left-16 top-1/2 transform -translate-y-1/2">
                <vs-tooltip>
                  <svg-icon icon-class="email_confirmed" className="gray-border"></svg-icon>
                  <template #tooltip>此會員的電子信箱已認證</template>
                </vs-tooltip>
              </div>

              <div class="title_section">
                <div class="title flex flex-col">
                  <span class="text-limit-1">{{ item.uname }}</span>
                  <span class="text-limit-1">{{ item.email }}</span>
                </div>

                <!-- 會員等級 -->
                <div class="w-36">
                  <span v-if="item.vip === '無'" class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">不指定</span>
                  <span v-else class="bg-primary text-white rounded-full px-3 py-1">{{ item.vip }}</span>
                </div>

                <!-- 總消費金額 -->
                <div class="w-36">
                  <span>NT${{ item.opays | currency }}</span>
                </div>

                <!-- 總消費筆數 -->
                <div class="w-24">
                  <span>{{ item.ocounts }} 筆</span>
                </div>

                <!-- 註冊時間 -->
                <span style="width: 187px">{{ item.cdate }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 位</span>
    </div>

    <!-- 刪除會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 位會員？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的會員無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 側邊篩選選單 -->
    <ValidationObserver v-slot="{ invalid }">
      <drawer>
        <template #header>
          <h1>進階篩選</h1>
        </template>

        <section class="space-y-4">
          <div>
            <h4 class="h4 mb-1">總消費金額</h4>
            <ValidationProvider
              tag="div"
              class="relative"
              mode="aggressive"
              name="總消費金額"
              rules="numeric|min_value:0"
              v-slot="{ errors }"
            >
              <div class="flex items-center space-x-2">
                <span>NT$</span>
                <vs-input class="w-full" placeholder="篩選消費大於等於此金額的會員" v-model="opays" />
              </div>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>

          <div>
            <h4 class="h4 mb-1">總消費筆數</h4>
            <ValidationProvider
              tag="div"
              class="relative"
              mode="aggressive"
              name="總消費筆數"
              rules="numeric|min_value:0"
              v-slot="{ errors }"
            >
              <vs-input placeholder="篩選消費大於等於此筆數的會員" v-model="ocounts" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>

          <div>
            <h4 class="h4 mb-1">生日月份</h4>
            <vs-select v-model="month">
              <vs-option v-for="item in monthAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
          <div>
            <h4 class="h4 mb-1">性別</h4>
            <vs-select v-model="sex">
              <vs-option v-for="item in sexAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>

          <div>
            <h4 class="h4 mb-1">信箱是否已驗證</h4>
            <vs-select v-model="eactivate">
              <vs-option v-for="item in eactivateAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
        </section>

        <template #footer>
          <div class="flex items-center justify-between w-full">
            <div>
              <vs-button size="large" transparent class="font-medium" @click="filterReset" color="danger"> 清除全部 </vs-button>
            </div>
            <div class="flex items-center justify-center space-x-4">
              <vs-button size="large" transparent class="font-medium" @click="close_drawer"> 取消 </vs-button>
              <vs-button @click="filterConfirm(), close_drawer()" size="large" class="font-medium" :disabled="invalid" ref="filterBtn">
                <span>確定</span>
              </vs-button>
            </div>
          </div>
        </template>
      </drawer>
    </ValidationObserver>
  </div>
</template>

<script>
import drawer from '@/components/drawer.vue'
export default {
  name: 'customers',
  components: {
    drawer,
  },
  data() {
    return {
      is_API_loaded: false, // 判斷並呈現骨架屏
      searchTitle: '',
      searchTitleBak: '',
      filtered: [],
      selectAll: false,
      indeterminate: false,
      selected: [],
      customers: [],
      pagination: {},
      currentPage: 1,
      delete_dialog: false, // 刪除會員 dialog
      defaultQuery: {}, // 預設$route.query
      eactivate: -99,
      eactivateAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '未驗證',
          value: 0,
        },
        {
          label: '已驗證',
          value: 1,
        },
      ],
      month: -99,
      monthAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '一月',
          value: 1,
        },
        {
          label: '二月',
          value: 2,
        },
        {
          label: '三月',
          value: 3,
        },
        {
          label: '四月',
          value: 4,
        },
        {
          label: '五月',
          value: 5,
        },
        {
          label: '六月',
          value: 6,
        },
        {
          label: '七月',
          value: 7,
        },
        {
          label: '八月',
          value: 8,
        },
        {
          label: '九月',
          value: 9,
        },
        {
          label: '十月',
          value: 10,
        },
        {
          label: '十一月',
          value: 11,
        },
        {
          label: '十二月',
          value: 12,
        },
      ],
      sex: -99,
      sexAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '男',
          value: 1,
        },
        {
          label: '女',
          value: 2,
        },
        {
          label: '不提供',
          value: 0,
        },
      ],
      opays: '',
      ocounts: '',
      filterBoxAry: {
        opays: {
          label: '總消費金額大於等於: ',
          status: '',
        },
        ocounts: {
          label: '總消費筆數大於等於: ',
          status: '',
        },
        sex: {
          label: '性別: ',
          status: '',
        },
        month: {
          label: '生日月份: ',
          status: '',
        },
        eactivate: {
          label: '信箱是否已驗證: ',
          status: '',
        },
      },
    }
  },
  computed: {
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  watch: {
    '$route.query'() {
      this.filterBoxList()
    },
  },
  created() {
    let query = this.$route.query
    this.defaultQuery = query
    this.currentPage = parseInt(query.page) || 1
    this.searchTitle = query.search || ''
    this.searchTitleBak = query.search || ''

    this.eactivate = parseInt(query.eactivate) || -99
    this.month = parseInt(query.month) || -99
    this.sex = parseInt(query.sex) || -99
    this.opays = query.opays ? parseInt(query.opays) : ''
    this.ocounts = query.ocounts ? parseInt(query.ocounts) : ''

    this.filterBoxList()
    this.loadCustomer()
  },
  methods: {
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.loadCustomer([['page', this.currentPage]])
    },
    // 篩選標題時觸發
    search(e) {
      if (this.searchTitle === this.searchTitleBak) return
      this.currentPage = 1
      this.loadCustomer([
        ['page', 1],
        ['search', this.searchTitle],
      ])
      this.searchTitleBak = this.searchTitle
    },
    // 3.2.1.1 讀取會員列表
    loadCustomer(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '' || routeQuery[key] === -99) {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      this.$axios({
        url: 'front/store/member/getMemberList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: routeQuery.page ? routeQuery.page : 1,
          uname: routeQuery.search ? routeQuery.search : '',
          eactivate: routeQuery.eactivate !== undefined ? routeQuery.eactivate : -99,
          month: routeQuery.month !== undefined ? routeQuery.month : -99,
          sex: routeQuery.sex !== undefined ? routeQuery.sex : -99,
          opays: routeQuery.opays !== undefined ? routeQuery.opays : -99,
          ocounts: routeQuery.ocounts !== undefined ? routeQuery.ocounts : -99,
        },
      }).then((res) => {
        if (res.data.Success) {
          console.log(res.data, '讀取會員列表')
          this.is_API_loaded = true
          this.customers = res.data.Data
          this.pagination = res.data.Page
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 開啟drawer選單
    open_drawer() {
      this.$store.commit('DRAWER_TOGGLER', {
        name: 'DRAWER_TOGGLER',
      })
      this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // 關閉drawer選單
    close_drawer() {
      // this.$store.commit('DRAWER_TOGGLER', {
      //   name: '',
      // })
      this.$store.dispatch('closeOverlay')
    },
    // 進階篩選確定
    filterConfirm() {
      this.loadCustomer([
        ['eactivate', this.eactivate],
        ['month', this.month],
        ['sex', this.sex],
        ['opays', this.opays],
        ['ocounts', this.ocounts],
        ['page', 1],
      ])
    },
    // query資料改動時，頁面上呈現篩選狀態
    filterBoxList() {
      let query = this.$route.query

      function findIndex(ary, status) {
        return ary.findIndex((e) => e.value === parseInt(status))
      }

      // 消費金額
      if (query.opays > 0) {
        this.filterBoxAry.opays.status = 'NT$' + this.$options.filters.currency(this.opays)
      } else {
        this.filterBoxAry.opays.status = ''
      }

      // 消費筆數
      if (query.ocounts > 0) {
        this.filterBoxAry.ocounts.status = this.ocounts + ' 筆'
      } else {
        this.filterBoxAry.ocounts.status = ''
      }

      // 信箱驗證
      if (query.eactivate >= 0) {
        let index = findIndex(this.eactivateAry, query.eactivate)
        this.filterBoxAry.eactivate.status = this.eactivateAry[index].label
      } else {
        this.filterBoxAry.eactivate.status = ''
      }

      // 生日月份
      if (query.month >= 0) {
        let index = findIndex(this.monthAry, query.month)
        this.filterBoxAry.month.status = this.monthAry[index].label
      } else {
        this.filterBoxAry.month.status = ''
      }

      // 性別
      if (query.sex >= 0) {
        let index = findIndex(this.sexAry, query.sex)
        this.filterBoxAry.sex.status = this.sexAry[index].label
      } else {
        this.filterBoxAry.sex.status = ''
      }
    },
    // 篩選顯上區塊，按叉叉後
    filterBoxCancel(box, str) {
      // 取消filter區塊按鈕顯示
      this.filterBoxAry[str].status = ''

      // 若是一些狀態，預設-99
      if (str === 'eactivate' || str === 'month' || str === 'sex') {
        this[str] = -99
      } else {
        this[str] = ''
      }
      this.filterConfirm()
    },
    // 清除全部篩選
    filterReset() {
      this.eactivate = -99
      this.month = -99
      this.sex = -99
      this.opays = ''
      this.ocounts = ''
      this.filterConfirm()
      this.close_drawer()
    },
    // 批次操作 刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/dMemberData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.loadCustomer(1)
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.customers.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.customers.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.customers.length; i++) {
          this.selected.push(this.customers[i].smno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
  // 如果drawer是開的，離開路由時把它關上。
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.drawer) {
      // this.close_drawer()
      this.$store.dispatch('closeOverlay')
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}

.filter_section {
  @apply px-4 flex flex-wrap;

  .filter_box {
    @apply bg-gray-200 rounded-xl h-10 px-3 space-x-1
    flex items-center justify-center mr-2 mb-2;
  }
}
</style>
